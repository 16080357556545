import * as React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import {useTranslation} from "gatsby-plugin-react-i18next"
import Container from "../components/container"
import { graphql } from "gatsby"

const HelpPage = () => {

    const pageprops = { title: "Dopa", description: "Privacy", image: { file: { url: "sdafasdf"}} };
    const {t} = useTranslation();

    return (
        <Layout {...pageprops}>
        <Page>
        <p>&nbsp;</p>
        <Container>
            <h2>{t("FAQ")}</h2>
            <p>&nbsp;</p>
            <h3>{t("HowdoesDopawork")}</h3>
            <p>{t("HowdoesDopaworkDescription")}</p>
            <h3>{t("Whichdevicesaresupported")}</h3>
            <p>{t("WhichdevicesaresupportedDescription")}</p>
            <h3>{t("HowcanIturnoffaudio")}</h3>
            <p>{t("HowcanIturnoffaudioDescription")}</p>
            <h3>{t("HowcanIturnoffspeechrecognition")}</h3>
            <p>{t("HowcanIturnoffspeechrecognitionDesciption")}</p>
            <h3>{t("IsDopafree")}</h3>
            <p>{t("IsDopafreeDescription")}</p>
            <h3>{t("DoIhavetologintouseDopa")}</h3>
            <p>{t("DoIhavetologintouseDopaDescription")}</p>
        </Container>
        </Page>
        </Layout>
    )
}

export default HelpPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;